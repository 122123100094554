<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->

      <b-row v-if="idRol == 3">
        <b-col cols="12" xl="12" lg="12" md="12">
          <user-view-user-info-card :user-data="userData" />
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="12" xl="6" lg="6" md="6">
          <user-view-user-info-card
            v-if="recharge"
            :user-data="userData"
            :grafico="graficCommission"
          />
        </b-col>

        <b-col cols="12" md="6" xl="6" lg="6">
          <user-view-user-plan-card
            v-if="recharge"
            :grafico="graficCommission"
          />
        </b-col>
      </b-row>
      <b-row v-if="idRol != 3">
        <b-col cols="12" lg="6">
          <user-view-user-permissions-card />
        </b-col>
        <b-col cols="12" lg="6">
          <user-view-user-timeline-card />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import {
  ref,
  onUnmounted,
  onMounted,
  onUpdate,
  watchEffect,
} from "@vue/composition-api";
import { BRow, BCol, BAlert, BLink } from "bootstrap-vue";

import userStoreModule from "../employeeStoreModule";
import UserViewUserInfoCard from "./EmployeeViewInfo.vue";
import UserViewUserPlanCard from "./EmployeeViewTopTips.vue";
import UserViewUserTimelineCard from "./EmployeeViewOffices.vue";
import UserViewUserPermissionsCard from "./EmployeViewServices.vue";
import useGraficEmployeeList from "./useGraficEmployeeList";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    UserViewUserTimelineCard,
    UserViewUserPermissionsCard,
  },
  setup() {
    const userData = ref(null);

    const USER_APP_STORE_MODULE_NAME = "app-user";
    const parser = JSON.parse(localStorage.getItem("userData"));
    const idRol = parser.userData.rolId;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const { idE, fetchUsers, graficCommission, recharge } =
      useGraficEmployeeList();
    fetchUsers();

    idE.value = router.currentRoute.params.id;
    store
      .dispatch("app-user/fetchUser", { id: router.currentRoute.params.id })
      .then((response) => {
        userData.value = response.data;
      })
      .catch((error) => {
        console.log("Que error", error);
      });

    return {
      userData,
      idE,
      graficCommission,
      recharge,
      fetchUsers,
      idRol,
    };
  },
};
</script>

<style></style>
