<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col cols="12" class="d-flex justify-content-between flex-column">
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start mt-3">
          <b-avatar :src="userData.photo" size="104px" rounded />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.fullName }}
              </h4>
              <span class="card-text">{{ userData.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{
                  name: 'apps-employee-edit',
                  params: { id: userData.id },
                }"
                variant="primary"
              >
                Edit
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" class="mt-4">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">Full Name</span>
            </th>
            <td class="pb-50">
              {{ userData.first_name }} {{ userData.last_name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="MailIcon" class="mr-75" />
              <span class="font-weight-bold">Email</span>
            </th>
            <td class="pb-50">
              {{ userData.mail }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="SmartphoneIcon" class="mr-75" />
              <span class="font-weight-bold">Phone Number</span>
            </th>
            <td class="pb-50">
              {{ userData.phone_number }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon icon="MapPinIcon" class="mr-75" />
              <span class="font-weight-bold">Address</span>
            </th>
            <td class="pb-50">
              {{ userData.adress }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CalendarIcon" class="mr-75" />
              <span class="font-weight-bold">Date of Birth</span>
            </th>
            <td class="pb-50">
              {{ userData.birth_date }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.status }}
            </td>
          </tr>
        </table>
        <!-- User Stats -->
        <div class="d-flex align-items-center mt-4">
          <div class="d-flex align-items-center">
            <b-avatar variant="light-success" rounded>
              <feather-icon icon="TrendingUpIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">{{ totalSalesProduct }}</h5>
              <small>Products Sales</small>
            </div>
          </div>
          <div class="d-flex align-items-center ml-2">
            <b-avatar variant="light-success" rounded>
              <feather-icon icon="TrendingUpIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">{{ totalSalesService }}</h5>
              <small>Services Sales</small>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol } from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import useUsersList from "../employee-list/useEmployeeList";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    grafico: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    console.log("DESDE ESTO PROP", props.grafico);
    let totalSalesProduct = props.grafico[0].total_sales_product;
    let totalSalesService = props.grafico[0].total_sales_service;

    return {
      avatarText,
      totalSalesProduct,
      totalSalesService,
    };
  },
};
</script>

<style></style>
