import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "photo", label: "Avatar", sortable: true },
    { key: "identification_number", label: "ID", sortable: true },

    { key: "first_name", sortable: true },
    { key: "last_name", sortable: true },
    { key: "officeEmployee[0].office.name", label: "office", sortable: true },
    { key: "mail", label: "Email", sortable: true },
    { key: "phone_number", sortable: true },
    { key: "city.name", label: "city", sortable: true },
    { key: "birth_date", sortable: true },
    { key: "status", sortable: true },
    { key: "actions" },
  ];

  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const statusFilter = ref(null);
  const itemsCountries = ref([]);
  const itemsStatus = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, roleFilter, statusFilter], () => {
    refetchData();
  });

  const parser = JSON.parse(localStorage.getItem("userData"));
  const idOffice = parser.dataUser.officeEmployee_officeId;
  const idRol = parser.userData.rolId;

  const fetchUsers = (ctx, callback) => {
    const { officeEmployee_officeId, rolId, dataUser, Employeess_id } =
      JSON.parse(localStorage.getItem("userData")).dataUser;
    store
      .dispatch("app-user/fetchUsers", {
        q: searchQuery.value.toLowerCase(),
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        officeFilter: roleFilter.value,
        status: statusFilter.value,
        idOffice,
        idRol,
        employeeId: rolId === 4 ? Employeess_id : null,
      })
      .then((response) => {
        const { data, total, officeData } = response.data;
        callback(data);
        totalUsers.value = total;
        const arrayCountry = [];
        const arrayData = [];
        const arrayOffice = [];
        const setObj = new Set();
        const setCity = new Set();
        const setOffice = new Set();

        data.map((data, item) => {
          if (!setCity.has(data.city.name)) {
            setCity.add(data.city.name, data);
            arrayCountry.push({
              label: data.city.name,
              value: data.city.id,
              id: item,
            });
          }

          if (!setObj.has(data.status)) {
            setObj.add(data.status, data);
            arrayData.push({
              label: data.status === true ? "Active" : "Inactive",
              value: data.status,
              id: item,
            });
          }
        });

        officeData.map((data, item) => {
          data.officeEmployee.map((data1, index) => {
            if (!setOffice.has(data1.office.name)) {
              setOffice.add(data1.office.name, data1);
              arrayOffice.push({
                label: data1.office.name,
                value: data1.office.id,
                id: data1.office.id,
              });
            }
          });
        });

        itemsCountries.value = arrayOffice;
        itemsStatus.value = arrayData;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === "subscriber") return "primary";
    if (role === "author") return "warning";
    if (role === "maintainer") return "success";
    if (role === "editor") return "info";
    if (role === "admin") return "danger";
    return "primary";
  };

  const resolveUserRoleIcon = (role) => {
    if (role === "subscriber") return "UserIcon";
    if (role === "author") return "SettingsIcon";
    if (role === "maintainer") return "DatabaseIcon";
    if (role === "editor") return "Edit2Icon";
    if (role === "admin") return "ServerIcon";
    return "UserIcon";
  };

  const resolveUserStatusVariant = (status) => {
    if (status === true) return "success";
    if (status === false) return "warning";
    return "success";
  };

  const dataItemCountries = itemsCountries;
  const dataItemStatus = itemsStatus;

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    statusFilter,
    dataItemStatus,
    dataItemCountries,
  };
}
