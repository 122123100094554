<template>
  <b-row>
    <e-charts
      ref="line"
      autoresize
      :options="option"
      theme="theme-color"
      auto-resize
    />
  </b-row>
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/pie";
import theme from "./theme.json";
import { BRow, BCol, BAlert, BLink } from "bootstrap-vue";

ECharts.registerTheme("theme-color", theme);

export default {
  components: {
    ECharts,
    BRow,
    BCol,
    BAlert,
    BLink,
  },
  props: {
    series: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          left: 10,
          bottom: "0",
        },
        series: this.series,
      },
    };
  },
};
</script>
