<template>
  <b-card no-body>
    <b-card-body>
      <b-card-title>Offices</b-card-title>
      <!-- <b-card-sub-title>Permission according to roles</b-card-sub-title> -->
    </b-card-body>
    <b-table striped responsive :items="offices" class="mb-0"></b-table>
  </b-card>
</template>

<script>
import router from '@/router'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import userStoreModule from '../employeeStoreModule'

import {
  BCard,
  BTable,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const offices = ref([])
    store
      .dispatch('app-user/fetchEmployeesOffices', {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        const arrayoffices = []
        response.data.data.map((data, item) => {
          arrayoffices.push({
            id: data.id,
            'Id Office': data.id_office,
            name: data.name,
          })
        })
        offices.value = arrayoffices
      })
      .catch((error) => {
        console.log(error)
      })

    return {
      offices,
    }
  },
}
</script>

<style></style>
