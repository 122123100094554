<template>
  <b-card title="Commissions And Tips Generated">
    <div class="pie-text text-center">
      <span class="font-weight-bold m-t-6">Total Commission Sales</span>
      <h2 class="font-weight-bolder">${{ totalSales }}</h2>
    </div>

    <!-- echart -->
    <app-echart-doughnut :series="serie" />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import AppEchartDoughnut from "@core/components/charts/echart/AppEchartDoughnut.vue";
import router from "@/router";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import userStoreModule from "../employeeStoreModule";

export default {
  components: {
    BCard,
    AppEchartDoughnut,
  },
  props: {
    grafico: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    let serie = props.grafico;
    let totalSales = serie[0].total_sales;

    return {
      serie,
      totalSales,
    };
  },
};
</script>

<style lang="scss">
.card-body {
  position: relative;
  .pie-text {
    width: 105px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 44%;
    bottom: 0;
  }
  .echarts {
    width: 100% !important;
    height: 400px;
  }
}
</style>
