import { ref, watch } from "@vue/composition-api";
import store from "@/store";

// Notification

export default function useUsersList() {
  // Use toast

  const recharge = ref(false);
  const idE = ref(0);
  const graficCommission = ref(null);

  watch([idE], () => {
    recharge.value = false;
    fetchUsers();
  });

  const parser = JSON.parse(localStorage.getItem("userData"));
  const idOffice = parser.dataUser.officeEmployee_officeId;
  const idRol = parser.userData.rolId;
  const fetchUsers = (ctx, callback) => {
    if (idE.value > 0) {
      store
        .dispatch("app-user/fetchCommision", {
          employeeId: idE.value,
          idRol,
          idOffice,
        })
        .then((response) => {
          const {
            total_pay_product,
            total_pay_service,
            total_pay_tips,
            totalcandt,
            total_sales_product,
            total_sales_service,
          } = response.data.paymentEmployees[0];

          console.log("Que trae", response.data.paymentEmployees[0]);
          graficCommission.value = [
            {
              name: "Total Pay",
              type: "pie",
              radius: ["50%", "70%"],
              avoidLabelOverlap: false,
              label: { show: false },
              labelLine: { show: false },
              data: [
                { value: total_pay_product, name: "Commission Products" },
                { value: total_pay_service, name: "Commission Services" },
                { value: total_pay_tips, name: "Tips" },
              ],
              total_sales: totalcandt,
              total_sales_product,
              total_sales_service,
            },
          ];
          recharge.value = true;
        })
        .catch((err) => {
          toast({
            component: ToastificationContent,
            props: {
              title: `Error fetching dashboardDataGlobal list ${err}`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    }
  };

  return {
    fetchUsers,
    recharge,
    idE,
    graficCommission,
    recharge,
  };
}
